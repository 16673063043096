import Client from 'shopify-buy'
import shopifyPlugin from '@data/sanity/shopifyPlugin'

const { accessToken, storeName } = shopifyPlugin.settings || {}

if (!accessToken){
	console.error(`Shopify access token not found in Sanity data`)
}
if (!storeName){
	console.error(`Shopify store name not found in Sanity data`)
}

const client = (Client && Client.buildClient) ? Client.buildClient({
	storefrontAccessToken: accessToken,
	domain: `${storeName}.myshopify.com`,
}) : {}

export default client