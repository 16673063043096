import React from 'react'
import { Helmet } from 'react-helmet'

export default function HiddenPageHeaders(props){
	const { hidePage } = props.page || {}

	if (!hidePage) return null

	return (
		<Helmet>
			<meta name='robots' content='noindex, nofollow' />
		</Helmet>
	)
}