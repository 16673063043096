import client from '@app/shopify-client'
import { useEffect, useRef, useReducer } from 'react'

const timeToStale = 5 * 60 * 1000 // 5 minutes

export default function useShopifyProduct(shopifyId){
	const cache = useRef({})

	const initialState = {
		status: `idle`,
		error: null,
		data: {},
	}

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case `FETCHING`:
				return { ...initialState, status: `loading` }
			case `FETCHED`:
				return { ...initialState, status: `loaded`, data: action.payload }
			case `FETCH_ERROR`:
				return { ...initialState, status: `error`, error: action.payload }
			default:
				return state
		}
	}, initialState)

	useEffect(() => {
		let cancelRequest = false
		if (!shopifyId) return

		const fetchData = async () => {
			dispatch({ type: `FETCHING` })

			const now = new Date()

			if (cache.current[shopifyId] && (now - cache.current[shopifyId].time) < timeToStale) {
				const data = cache.current[shopifyId].data
				dispatch({ type: `FETCHED`, payload: data })
			} else {
				try {
					const data = await client.product.fetch(shopifyId)
					cache.current[shopifyId] = {
						time: new Date(),
						data,
					}
					if (cancelRequest) return
					dispatch({ type: `FETCHED`, payload: data })
				} catch (error) {
					if (cancelRequest) return
					dispatch({ type: `FETCH_ERROR`, payload: error.message })
				}
			}
		}

		fetchData()

		return function cleanup() {
			cancelRequest = true
		}
	}, [shopifyId])

	return state
}