import React from 'react'
import templates from '@rdk/config/react-product-templates'
import pageHeaders from '@rdk/config/react-page-headers'

export default function ProductPage(props) {
	return (
		<>
			{pageHeaders.map((Header, key) => {
				return (
					<Header key={key} page={props.pageContext.product} />
				)
			})}
			{templates.map((Template, index) => (
				<Template key={index} {...props} />
			))}
			{!templates.length && (
				<div>No product page templates found.</div>
			)}
		</>
	)
}