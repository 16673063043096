import React from 'react'
import { Helmet } from 'react-helmet'
import siteConfig from '@data/sanity/siteSettings'

export default function Head(props) {
	const pageTitle = props.title ? `${props.title} | ${siteConfig.title}` : siteConfig.title
	return <>
		<Helmet>
			<html lang={props.lang || `en`} />
			<title>{pageTitle}</title>
			{props.children}
		</Helmet>
	</>
}